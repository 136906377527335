// Backend URLs
// export const backendURL = 'https://avogadro.azurewebsites.net';
export const backendURL = 'http://localhost:8000'; // For local Django Server

// Key for Autocomplete API
export const AutocompleteKey = '2ov7JqmEAsdGnP51xLa0tDQ7GcxUx6ieeZ1XP8QT8JAzSeBfb6PD';
export const AutocompleteVer = '2019-05-06';
export const AutoCompleteTop = 25; // Number of results to be fetched from the API
export const AutoCompleteRes = 25; // Max. no of results to be populated in the AutoComplete list

const domain = 'https://codecarbonhq-avogadrosearch.search.windows.net/';
const path = 'indexes/';

// Inspection List:
export const InspectionSuggester = 'inspection_suggester';
// export const InspectionFields = 'country,city,site_address,company_name,normalized_company_city';
export const InspectionFields = 'country,normalized_company_city';
export const InspectionURL = `${domain + path}fda-inspections-index/docs/suggest`;

// Sites List:
export const SiteSuggester = 'site_suggester';
// export const SiteFields =
//  'name,company_name,company_address,city,country,address,normalized_company_city';
export const SiteFields = 'name,company_name,country,normalized_company_city';
export const SiteURL = `${domain + path}fda-site-index/docs/suggest`;

export const UsfdaRecallSiteSuggester = 'usfda_recall_data_suggester';
export const RecallSiteFields = 'recalling_firm_name,country,fei_number';
export const UsfdaRecallURL = `${domain + path}usfda_recall_index/docs/suggest`;
export const recallProductBaseUrl = 'https://www.accessdata.fda.gov/scripts/ires/?Product=';
// Mhra_Eu autocomplete List data api:

export const MhraEuSuggester = 'mhra_eu_inspection_suggester';
export const MhraEuFields = 'site_name,site_address,country,normalized_site_name';
export const MhraEuURL = `${domain + path}mhra-eu-inspections-index/docs/suggest`;

// Inspectors List:
export const InspectorSuggester = 'investigator_index_suggester';
export const InspectorFields =
  'normalized_with_middle_name,normalized_name,first_name,last_name,organization';
export const InspectorURL = `${domain + path}fda-investigator-index/docs/suggest`;

// Warning Letters List:
export const WLSuggester = 'warning_letter_suggester';
export const WLFields =
  'warning_letter_subject,warning_letter_content,warning_letter_company,warning_letter_issuing_office';
export const WLURL = `${domain + path}fda-warning-letter-index/docs/suggest`;

// Drug Analytics List:
export const DrugSuggester = 'drug_suggester';
export const DrugFields = 'brand_name,generic_name';
export const DrugURL = `${domain + path}fda-drug-content-index/docs/suggest`;

// Company Profile List
export const CompanySuggester = 'company_suggester';
export const CompanyFields = 'company_name';
export const CompanyURL = `${domain + path}fda-company-profile-index/docs/suggest`;

// Observation Content Suggester
export const ObsSuggester = 'observation_content_suggester';
export const ObsCitationFields = 'matched_citation_reference_list';
export const ObsURL = `${domain + path}fda-observation-content-index/docs/suggest`;

// Downloads APIs
const downloadAPI = '/inspections/api/downloads/';
export const download483 = `${downloadAPI}483`;
export const download483r = `${downloadAPI}483_response`;
export const downloadeir = `${downloadAPI}eir`;
export const downloademhraeu = `${downloadAPI}mhra_eu_gmp`;
export const downloadtrending483 = `${downloadAPI}trendings483`;

// WatchList Constants
export const watchListAddTypeString = 'Add';
export const watchListRemoveTypeString = 'Remove';
export const watchListUpdateVisitedTypeString = 'UpdateVisited';
export const watchListUpdateSuccessString = 'Success';
export const watchListUpdateFailString = 'Fail';

export const filterOptions = require('@/assets/filters_data.json');
export const drugFilterOptions = require('@/assets/drug_filters_data.json');
export const citationFilterOptions = require('@/assets/citation_filters_data.json');
export const countryNameCodeMap = require('@/assets/mapping_data.json');

// GeoLocation: https://geolocation-db.com
export const GeoLocationKey = '0f761a30-fe14-11e9-b59f-e53803842572';

// SEO Enopoints
const env = window.location.href.includes('localhost') ? 'dev' : 'prod';

const JSONURL = 'https://fdalytics-data-dagsd0gagyhdg5dj.z02.azurefd.net';
// const JSONURL = 'https://avogadrodevtest.blob.core.windows.net';

const SEOBase = `${JSONURL}/seo-data/${env}/seo_base`;

export const SEOListEP = `${SEOBase}/list_folder/investigators/`;
export const SEOProfileEP = `${SEOBase}/profile_folder/investigators/`;

export const SiteMapEP = `${SEOBase}/sitemap.xml`;

export const SUBSCRIBER_USER_TYPE = 6666;
// super user having access of both fda and mhra_eu
export const SUBSCRIBER_SUPER_USER_TYPE = 9999;

// user for Medical device
export const SUBSCRIBER_MEDICAL_DEVICE_USER_TYPE = 1111;

export const DATA_TYPING_USER_TYPE = 2222;

export const domainURL = 'http://localhost:8000';
// export const domainURL = 'https://www.fdalytics.com';

export const testSubEmail = 'test_subscribed@dhan.ai';
export const testSubPW = 'testAc';

export const testNonSubEmail = 'test_nonsub@fdalytics.com';
export const testNonSubPW = 'testNSAc';

export const testingTeam = [
  'vishrut@fdalytics.com',
  'chirag@fdalytics.com',
  'sairohanj@fdalytics.com',
  'leomessi@fcb.cat',
  'bhavish@dhan.ai',
  'chakri@dhan.ai',
  'bhavish@fdalytics.com',
  'chakri@fdalytics.com',
  'chiragsoni.soni007@gmail.com',
  'rohan@gmail.com',
];

export const calendlyLink = 'https://calendly.com/demo-kspr/30min';
